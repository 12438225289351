<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <!--
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2>
      </b-link>
      -->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
              fluid
              :src="imgUrl"
              alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <template v-if="isCodeValidationMode">

        <!-- Reset Password Mode  -->
        <template v-if="isResetPasswordMode">

          <b-col
              lg="4"
              class="d-flex align-items-center auth-bg px-2 p-lg-5"
          >
            <b-col
                sm="8"
                md="6"
                lg="12"
                class="px-xl-2 mx-auto"
            >
              <b-card-title
                  title-tag="h2"
                  class="font-weight-bold mb-1"
              >
                Restablecer contraseña
              </b-card-title>
              <b-card-text class="mb-2">
                Ingresa una nueva contraseña y confírmala para restablecer el acceso a tu cuenta.
              </b-card-text>

              <!-- form -->
              <b-form
                  class="auth-forgot-password-form mt-2"
              >
                <!-- password -->
                <b-form-group
                    label="Nueva contraseña"
                    label-for="reset-password-new"
                >
                  <b-input-group
                      class="input-group-merge"
                  >
                    <b-form-input
                        id="reset-password-new"
                        v-model="password"
                        :type="password1FieldType"
                        class="form-control-merge"
                        name="reset-password-new"
                        autocomplete="off"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <!-- confirm password -->
                <b-form-group
                    label-for="reset-password-confirm"
                    label="Confirmar contraseña"
                >

                    <b-input-group
                        class="input-group-merge"
                    >
                      <b-form-input
                          id="reset-password-confirm"
                          v-model="cPassword"
                          :type="password2FieldType"
                          class="form-control-merge"
                          name="reset-password-confirm"
                          autocomplete="off"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            class="cursor-pointer"
                            :icon="password2ToggleIcon"
                            @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <!-- submit button -->
                <b-button
                    block
                    variant="primary"
                    :disabled="isBusy"
                    @click="handleSetNewPassword"
                >
                  <b-spinner small v-if="isBusy"/>
                  Establecer nueva contraseña
                </b-button>
              </b-form>

              <p class="text-center mt-2">
                <b-link :to="{name:'auth-signin'}">
                  <feather-icon icon="ChevronLeftIcon" /> Regresar al inicio de sesión
                </b-link>
              </p>

              <validation-errors v-if="errors" :errors="errors" class="mt-2"/>

            </b-col>
          </b-col>

        </template>
        <!-- Reset Password Mode -->

        <!-- Validation Code -->
        <template v-else>
          <b-col
              lg="4"
              class="d-flex align-items-center auth-bg px-2 p-lg-5"
          >
            <b-col
                sm="8"
                md="6"
                lg="12"
                class="px-xl-2 mx-auto"
            >
              <b-card-title
                  title-tag="h2"
                  class="font-weight-bold mb-1"
              >
                Código de seguridad
              </b-card-title>
              <b-card-text class="mb-2">
                Te hemos enviado un código de seguridad correo electrónico registrado.
              </b-card-text>

              <!-- form -->
              <b-form
                  class="auth-forgot-password-form mt-2"
              >

                <b-form-input
                    v-model="code"
                    placeholder="Ingresa tu código"
                    class="form-control-lg font-weight-bold text-center"
                    maxlength="6"
                    @input="allowOnlyDigits"
                />

                <b-button
                    variant="primary"
                    block
                    class="mt-2"
                    @click="handleVerifyCode"
                    :disabled="isBusy || isEmptyCode"
                >
                  <b-spinner small v-if="isBusy"/>
                  Verificar
                </b-button>

              </b-form>

              <p class="text-center mt-2">
                <b-link :to="{name:'auth-signin'}">
                  <feather-icon icon="ChevronLeftIcon" /> Regresar al inicio de sesión
                </b-link>
              </p>

              <validation-errors v-if="errors" :errors="errors" class="mt-2"/>

            </b-col>
          </b-col>
        </template>
        <!-- Validation Code -->

      </template>

      <template v-else>

        <!-- Forgot password-->
        <b-col
            lg="4"
            class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
              sm="8"
              md="6"
              lg="12"
              class="px-xl-2 mx-auto"
          >
            <b-card-title
                title-tag="h2"
                class="font-weight-bold mb-1"
            >
              ¿Olvidaste tu contraseña?
            </b-card-title>
            <b-card-text class="mb-2">
              Ingresa tu dirección de correo electrónico y te enviaremos una contraseña temporal para que puedas restablecer tu contraseña de manera segura
            </b-card-text>

            <!-- form -->
            <b-form
                class="auth-forgot-password-form mt-2"
            >
              <b-form-group
                  label="Correo electrónico"
                  label-for="forgot-password-email"
              >
                <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    name="forgot-password-email"
                    placeholder="Tu dirección de correo electrónico"
                />
              </b-form-group>

              <b-button
                  variant="primary"
                  block
                  @click="handleResetPassword"
                  :disabled="isBusy || isEmptyEmail"
              >
                <b-spinner small v-if="isBusy"/>
                Restablecer contraseña
              </b-button>
            </b-form>

            <p class="text-center mt-2">
              <b-link :to="{name:'auth-signin'}">
                <feather-icon icon="ChevronLeftIcon" /> Regresar al inicio de sesión
              </b-link>
            </p>

            <validation-errors v-if="errors" :errors="errors" class="mt-2"/>

          </b-col>

        </b-col>
        <!-- /Forgot password-->

      </template>




    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
} from 'bootstrap-vue'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ValidationErrors from '@/modules/components/ValidationErrors.vue'
import { forgotPassword, forgotPasswordVerifyCode, forgotPasswordReset } from '@/modules/auth/api'
import router from '@/router'

export default {
  components: {
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    ValidationErrors,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
  },
  data() {
    return {
      //
      errors: [],
      isBusy: false,
      //
      isCodeValidationMode: false,
      isResetPasswordMode: false,
      userEmail: '',
      uuidVerificationCode: null,
      user: null,
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      //
      code: null,
      //
      password: null,
      cPassword: null,
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',

    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    //verificar email ingresado
    isEmptyEmail(){
      return this.userEmail === '' || this.userEmail === null
    },
    //verificar codigo ingresado
    isEmptyCode(){
      return this.code === '' || this.code === null
    }

  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },

    //permitir solo digitos
    allowOnlyDigits(){
      this.code = this.code.match(/\d*/)[0];
    },

    // procesar peticion de olvido de contraseña
    async handleResetPassword() {

      try {

        this.uuidVerificationCode = null
        this.errors = []
        this.isBusy = true

        const params = {
          email: this.userEmail
        }

        // recibir uuid del registro para codigo de verificacion
        const { uuid: uuidVerificationCode } = await forgotPassword( params )

        // activar seccion para validar codigo de verificacion
        if ( uuidVerificationCode ){
          this.uuidVerificationCode = uuidVerificationCode
          this.isCodeValidationMode = true
        }

      } catch (error) {

        console.log("error", error)

        if ( error.response  ){

          // errores de validacion
          if ( error.response.status == 422 ) {

            console.log("error 422", error.response.data )
            // errores de validacion
            this.errors = error.response.data

            console.log(this.errors)

          }

        }

      } finally {

        this.isBusy = false

      }

    },

    // procesar codigo recibido antes restablecer la constrase
    async handleVerifyCode(){

      try {

        this.user = null
        this.errors = []
        this.isBusy = true

        const params = {
          uuid: this.uuidVerificationCode,
          code: this.code
        }

        const { user } = await forgotPasswordVerifyCode( params )

        if ( user ) {
          this.user = user
          this.isResetPasswordMode = true
        }

      } catch (error) {

        if ( error.response  ) {
          // errores de validacion 422, error de authenticacion 401
          if ( error.response.status == 422  || error.response.status == 401 ) {
            console.log("error 422", error.response.data)
            // errores de validacion
            this.errors = error.response.data
          }
        }

      } finally {

        this.isBusy = false
      }

    },

    // procesar peticion para establecer nueva contraseña
    async handleSetNewPassword(){
      try {

        this.errors = []
        this.isBusy = true
        const params = {
          uuid: this.user.usrUuid,
          password: this.password,
          confirm_password: this.cPassword
        }

        await forgotPasswordReset( params )


        //redigir al login
        await router.push({ name: 'auth-signin' })


        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Contraseña restablecida',
            icon: 'CheckIcon',
            text: 'Se ha restablecido tu contraseña con éxito.',
            variant: 'success'
          },
        })



      } catch (error) {
        if ( error.response  ) {
          // errores de validacion 422
          if ( error.response.status == 422) {

            if ( error.response.data?.errors )
              this.errors = error.response.data.errors
            else
              this.errors = error.response.data

          }
        }
      } finally {
        this.isBusy = false
      }

    }

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
